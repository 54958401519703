import { ISOLanguageCode } from "@utils/language";

// Spanish at the top; alphabetical by English name. English itself excluded.
export const languagesOrdered: ISOLanguageCode[] = [
  "spa",
  "ase",
  "ara",
  "hye",
  "ben",
  "bul",
  "yue",
  "ces",
  "fra",
  "deu",
  "ell",
  "hat",
  "heb",
  "hin",
  "hmn",
  "ind",
  "ita",
  "jpn",
  "kor",
  "cmn",
  "pol",
  "por",
  "pan",
  "ron",
  "rus",
  "swa",
  "tgl",
  "tam",
  "tur",
  "ukr",
  "urd",
  "vie",
];
