import { JobPostingStatus } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  status: JobPostingStatus;
  className?: string;
  teacherSide?: boolean;
};

export const JobPostingStatusBadge = ({
  status,
  className,
  teacherSide = false,
}: Props) => (
  <Badge className={clsx(jobPostingStatusMap[status], className)}>
    {getJobPostingStatusText(status, teacherSide)}
  </Badge>
);

export const getJobPostingStatusText = (
  status: JobPostingStatus,
  teacherSide: boolean
): string => {
  switch (status) {
    case JobPostingStatus.Published:
      switch (teacherSide) {
        case true:
          return "Open";
        default:
          return "Published";
      }
    case JobPostingStatus.Archived:
      return "Archived";
    case JobPostingStatus.Unpublished:
      switch (teacherSide) {
        case true:
          return "Closed";
        default:
          return "Unpublished";
      }
    default:
      return "Unknown Status";
  }
};

const jobPostingStatusMap: {
  [key in JobPostingStatus]: string;
} = {
  [JobPostingStatus.Published]: "text-emerald-900 bg-emerald-200",
  [JobPostingStatus.Archived]: "text-orange-900 bg-orange-200",
  [JobPostingStatus.Unpublished]: "text-red-900 bg-red-200",
};
