import { Icon } from "../Icon";

export const getCollapseHeader = (
  title: string,
  isCollapsed: boolean,
  setIsCollapsed: (isCollapsed: boolean) => void
) => (
  <div
    className="flex items-center justify-between w-full cursor-pointer group"
    onClick={() => setIsCollapsed(!isCollapsed)}
  >
    {title}
    <Icon
      size={7}
      icon={isCollapsed ? "chevronDown" : "chevronUp"}
      className="text-gray-500 group-hover:text-slate-800 pt-[2px]"
    />
  </div>
);

export const getEditModalOpenHeader = (
  title: string,
  openModal: () => void
) => (
  <div
    onClick={openModal}
    className="flex w-full items-center justify-between cursor-pointer group"
  >
    {title}
    <Icon
      size={6}
      icon="edit"
      color="text-gray-600 group-hover:text-blue-600"
    />
  </div>
);

export const getAddModalOpenHeader = (
  title: string,
  openAddModal: () => void
) => (
  <div
    onClick={openAddModal}
    className="flex w-full items-center justify-between cursor-pointer group"
  >
    {title}
    <Icon
      size={6}
      icon="plus"
      color="text-gray-600 group-hover:text-blue-600"
    />
  </div>
);
