import { getOrigin } from "@utils/browser";
import { Pixel } from "@utils/numbers";
import { getQrCodeImageUrl } from "@utils/qr";
import Image from "next/image";
import { QRCodeErrorCorrectionLevel } from "qrcode";
import { MinimalCopyButton } from "./Buttons/MinimalCopyButton";

type Props = {
  text: string;
  size: Pixel;
  margin?: number;
  ecLevel?: QRCodeErrorCorrectionLevel;
  alt?: string;
};

export default function QrCodeImage({
  text,
  size,
  margin,
  ecLevel,
  alt = "QR Code",
}: Props) {
  const imageUrl = getQrCodeImageUrl(text, size, margin, ecLevel);
  return (
    <>
      <Image
        className="my-2"
        src={imageUrl}
        height={size}
        width={size}
        alt={alt}
      />
      <MinimalCopyButton copyValue={getOrigin() + imageUrl} label="Copy link" />
    </>
  );
}
