import { ProficiencyRating } from "@generated/graphql";
import { getProficiencyRatingText } from "@utils/labels";
import { assertUnreachable } from "@utils/types";
import clsx from "clsx";
import { PillButton } from "../Buttons";

const selectedStyle = "border-2 text-white";
const defaultStyle = "border border-gray-300 bg-white text-gray-700";

type Props = {
  required?: boolean;
  className?: string;
  readOnly?: boolean;
  value: ProficiencyRating | null;
  yesNoSomewhatTextOutput?: boolean;
  onChange: (value: ProficiencyRating | null) => void;
};

export const ThreePointScaleInput = ({
  value,
  className,
  readOnly = false,
  required = false,
  yesNoSomewhatTextOutput = false,
  onChange,
}: Props) => (
  <div className={clsx("flex items-center gap-x-[5px]", className)}>
    {Object.values(ProficiencyRating)
      .filter((option) => !readOnly || option == value)
      .map((option) => {
        const isSelected = option == value;
        const optionName = yesNoSomewhatTextOutput
          ? getYesNoSomewhatText(option)
          : getProficiencyRatingText(option);

        return (
          <PillButton
            key={optionName}
            onChange={() => !readOnly && onChange(isSelected ? null : option)}
            className={clsx(
              readOnly && "!cursor-default",
              isSelected
                ? [selectedStyle, getOptionColor(option)]
                : defaultStyle
            )}
            name={
              <div className={clsx(!isSelected && "border border-white")}>
                {optionName}
              </div>
            }
          />
        );
      })}
    {required && !readOnly && (
      <span className="text-gray-800 text-sm -ml-[3px] mb-[4px] font-semibold">
        *
      </span>
    )}
  </div>
);

const getOptionColor = (option: ProficiencyRating) => {
  switch (option) {
    case ProficiencyRating.Proficient:
      return "border-emerald-600 bg-emerald-500 bg-opacity-90 text-white";
    case ProficiencyRating.Progressing:
      return "border-amber-600 bg-amber-500 bg-opacity-90 text-white";
    case ProficiencyRating.Struggling:
      return "border-rose-600 bg-rose-500 bg-opacity-80 text-white";
    default:
      assertUnreachable(option, "ProficiencyRating Option");
  }
};

const getYesNoSomewhatText = (value: ProficiencyRating): string =>
  value == ProficiencyRating.Proficient
    ? "Yes"
    : value == ProficiencyRating.Struggling
    ? "No"
    : value == ProficiencyRating.Progressing
    ? "Somewhat"
    : "";
