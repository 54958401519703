import { gql, useQuery } from "@apollo/client";
import {
  ExternalUserAccountType,
  OnboardingChecklistCard_UserFragment,
  OnboardingChecklistQuery,
  OnboardingChecklistQueryVariables,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { Card, ChecklistItem, ErrorBox } from "components/shared";
import { HeaderColors } from "components/shared/Card";
import { useMemo, useState } from "react";
import { FinishTaxFormsChecklistItem } from "./components/FinishTaxFormsChecklistItem";
import { InviteToSlackCheckListItem } from "./components/InviteToSlackChecklistItem";
import { InviteToTipaltiCheckListItem } from "./components/InviteToTipaltiChecklistItem";
import { PersonalInformationChecklistItem } from "./components/PersonalInformationChecklistItem";
import { ReferralDetailsChecklistItem } from "./components/ReferralDetailsChecklistItem";
import { SetupPaymentsChecklistItem } from "./components/SetupPaymentsChecklistItem";
import { SignAgreementsChecklistItem } from "./components/SignAgreementsChecklistItem";
import { TbtBasicsChecklistItem } from "./components/TbtBasicsChecklistItem";
import { TeachingExperienceChecklistItem } from "./components/TeachingExperienceChecklistItem";
import { TutoringPreferencesChecklistItem } from "./components/TutoringPreferencesChecklistItem";

OnboardingChecklistCard.fragments = {
  user: gql`
    fragment OnboardingChecklistCard_User on User {
      id
      isPaidExternally
      hasPendingAgreements
      completedAgreements {
        ...SignAgreementsChecklistItem_CompletedAgreement
      }
      ...ReferralDetailsChecklistItem_User
      ...PersonalInformationChecklistItem_User
      ...TutoringPreferencesChecklistItem_User
      ...TeachingExperienceChecklistItem_User
    }
    ${SignAgreementsChecklistItem.fragments.completedAgreement}
    ${ReferralDetailsChecklistItem.fragments.user}
    ${PersonalInformationChecklistItem.fragments.user}
    ${TutoringPreferencesChecklistItem.fragments.user}
    ${TeachingExperienceChecklistItem.fragments.user}
  `,
};

const GET_CURRENT_USERS_EXTERNAL_ACCOUNTS = gql`
  query OnboardingChecklist($userId: ID!) {
    userExternalAccounts(userId: $userId) {
      type
      isDeleted
      externalId
      isDisabled
      ...InviteToSlackCheckListItem_ExternalUserAccount
      ...InviteToTipaltiCheckListItem_ExternalUserAccount
    }
  }
  ${InviteToSlackCheckListItem.fragments.slackAccount}
  ${InviteToTipaltiCheckListItem.fragments.tipaltiAccount}
`;

type Props = {
  user: OnboardingChecklistCard_UserFragment;
  refetch: () => void;
};

export function OnboardingChecklistCard({ user, refetch }: Props) {
  const { isPaidExternally } = user;
  const [errorMsg, setErrorMsg] = useState("");

  const { data } = useQuery<
    OnboardingChecklistQuery,
    OnboardingChecklistQueryVariables
  >(GET_CURRENT_USERS_EXTERNAL_ACCOUNTS, {
    fetchPolicy: "network-only",
    variables: { userId: user.id },
    onError: (error) => fetchErrToast("your onboarding details", error),
  });

  const { tipaltiAccount, slackAccount } = useMemo(() => {
    if (!data?.userExternalAccounts)
      return {
        tipaltiAccount: null,
        slackAccount: null,
      };

    const tipaltiExternalAccount = data.userExternalAccounts.find(
      (account) =>
        account.type == ExternalUserAccountType.Tipalti &&
        account.isDisabled == false &&
        account.isDeleted == false
    );

    const slackExternalAccount = data.userExternalAccounts.find(
      (account) =>
        account.type == ExternalUserAccountType.Slack &&
        account.isDisabled == false &&
        account.isDeleted == false
    );
    return {
      tipaltiAccount: tipaltiExternalAccount || null,
      slackAccount: slackExternalAccount || null,
    };
  }, [data]);

  return (
    <Card
      icon="job"
      bodyClassName="!border-t-0 bg-white"
      headerOverlayColor={HeaderColors.Blue}
      header="Onboarding Checklist"
    >
      <div className="flex flex-col w-full h-fit p-2 bg-white gap-y-2">
        <ChecklistItem
          title="Setup and confirm your TbT Portal account"
          trackedForCompletion
          complete
        />
        <SignAgreementsChecklistItem
          hasPendingAgreements={user.hasPendingAgreements}
          completedAgreements={user.completedAgreements}
        />
        <PersonalInformationChecklistItem
          user={user}
          userId={user.id}
          refetch={refetch}
        />
        <TeachingExperienceChecklistItem user={user} refetch={refetch} />
        <TutoringPreferencesChecklistItem
          user={user}
          userId={user.id}
          refetch={refetch}
        />
        <ReferralDetailsChecklistItem user={user} refetch={refetch} />
        <InviteToSlackCheckListItem slackAccount={slackAccount} />

        {!isPaidExternally && (
          <>
            <InviteToTipaltiCheckListItem tipaltiAccount={tipaltiAccount} />
            <SetupPaymentsChecklistItem
              setError={setErrorMsg}
              userId={user.id}
            />
            <FinishTaxFormsChecklistItem
              setError={setErrorMsg}
              userId={user.id}
            />
          </>
        )}

        <TbtBasicsChecklistItem setError={setErrorMsg} userId={user.id} />

        <ErrorBox msg={errorMsg} />
      </div>
    </Card>
  );
}
