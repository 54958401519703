import { assertUnreachable } from "@utils/types";
import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  flex?: boolean;
  zIndex?: number;
  className?: string;
  margin?: MarginOptions;
  enableZIndex?: boolean;
  padding?: PaddingOptions;
};

type MarginOptions = "none" | "md";
type PaddingOptions = "none" | "md" | "lg";

export const Container = ({
  children,
  className,
  zIndex = 0,
  flex = false,
  padding = "md",
  margin = "none",
  enableZIndex = true,
}: Props) => (
  <div
    className={clsx(
      "bg-white shadow rounded-lg",
      getPadding(padding),
      getMargin(margin),
      flex && "flex flex-col gap-y-4",
      className
    )}
    style={{ zIndex: enableZIndex ? zIndex : undefined }}
  >
    {children}
  </div>
);

function getMargin(margin: MarginOptions) {
  switch (margin) {
    case "none":
      return null;
    case "md":
      return "mt-6";
    default:
      assertUnreachable(margin);
  }
}

function getPadding(padding: PaddingOptions) {
  switch (padding) {
    case "none":
      return null;
    case "md":
      return "p-4";
    case "lg":
      return "p-5";
    default:
      assertUnreachable(padding);
  }
}
