import { GradeLevel } from "@generated/graphql";
import { getGradeLevelText } from "@utils/labels";
import { clsx } from "clsx";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
import { Badge } from "./Badge";

type Props = {
  className?: string;
  gradeLevel: GradeLevel;
};

export const GradeLevelBadge = ({ className, gradeLevel }: Props) => (
  <Tooltip
    className={className}
    content="Instruction Level"
    tooltipProps={{ place: "right" }}
  >
    <Badge
      className={clsx(
        "h-5 !w-10 !font-semibold gap-x-px",
        "text-emerald-900 bg-emerald-300/80"
      )}
    >
      <Icon
        size={3}
        icon="grade"
        color="text-emerald-800"
        className="-ml-[3px]"
      />
      {getGradeLevelText(gradeLevel)}
    </Badge>
  </Tooltip>
);
