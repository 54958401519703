import { CalendarInfoHoliday } from "@generated/graphql";
import { normalizeToUtcISODate } from "@utils/dateTime";
import { addMonths, startOfMonth } from "date-fns";
import { CellRendererHoliday } from "./cellRenderers";
import { CALENDAR_WIDTH, NAV_BUTTONS_WIDTH } from "./constants";
import { BuildHolidayMapHoliday, OnUpdateViewpoint } from "./types";

// OnUpdateViewpoint specifies the position of the (newly) selected month in the calendar array
// (Past => end, Future => start, Present => centered, Static => same as before)
export const getFirstMonthDate = (
  viewDate: Date,
  initialDate: Date,
  calendarCount: number,
  navButtonOffset: number,
  onUpdateViewpoint: OnUpdateViewpoint
) => {
  let anchorDate = viewDate;
  const { Past, Present, Static } = OnUpdateViewpoint;

  let viewpointOffset = 0; // Static && Future OnUpdateViewpoints
  const lastCalendar = calendarCount - 1;
  const centerCalendar = Math.floor(calendarCount / 2);
  if (onUpdateViewpoint === Past) viewpointOffset = lastCalendar;
  if (onUpdateViewpoint === Present) viewpointOffset = centerCalendar;

  // Static Mode only uses initialDate && navButtonOffset to determine first month
  if (onUpdateViewpoint === Static) anchorDate = initialDate;

  // Total offset from anchorDate
  const totalMonthsOffset = navButtonOffset - viewpointOffset;

  return startOfMonth(addMonths(anchorDate, totalMonthsOffset));
};

export const buildDayHolidaysMap = <H extends BuildHolidayMapHoliday>(
  holidays: H[]
): Map<string, H[]> => {
  const dayHolidaysMap: Map<string, H[]> = new Map();
  for (const holiday of holidays) {
    const startISODate = normalizeToUtcISODate(new Date(holiday.startDate));
    const endISODate = normalizeToUtcISODate(new Date(holiday.endDate));

    for (
      let curDate = new Date(startISODate);
      curDate <= new Date(endISODate);
      curDate.setDate(curDate.getDate() + 1)
    ) {
      const curISODate = normalizeToUtcISODate(curDate);
      dayHolidaysMap.has(curISODate)
        ? dayHolidaysMap.set(
            curISODate,
            dayHolidaysMap.get(curISODate)!.concat(holiday)
          )
        : dayHolidaysMap.set(curISODate, [holiday]);
    }
  }

  return dayHolidaysMap;
};

export const buildDayEventsCountMap = (
  eventsInstancesStartTimes: Date[]
): Map<string, number> => {
  const dayEventsCountMap: Map<string, number> = new Map();

  for (const eventInstanceStartTime of eventsInstancesStartTimes) {
    const isoDate = normalizeToUtcISODate(new Date(eventInstanceStartTime));

    dayEventsCountMap.has(isoDate)
      ? dayEventsCountMap.set(
          isoDate,
          (dayEventsCountMap.get(isoDate) ?? 1) + 1
        )
      : dayEventsCountMap.set(isoDate, 1);
  }

  return dayEventsCountMap;
};

export const getShowMiniCalendar = (calendarsWidth: number) =>
  calendarsWidth - NAV_BUTTONS_WIDTH < CALENDAR_WIDTH;

export const getCalendarCount = (calendarsWidth: number) => {
  const viewWidth = calendarsWidth - NAV_BUTTONS_WIDTH;
  return Math.max(1, Math.floor(viewWidth / CALENDAR_WIDTH));
};

export const getCalendarHolidaysMap = (
  holidays: CalendarInfoHoliday[]
): Map<string, CellRendererHoliday[]> => {
  const dayHolidaysMap: Map<string, CellRendererHoliday[]> = new Map();
  for (const holiday of holidays) {
    const startISODate = normalizeToUtcISODate(new Date(holiday.startDate));
    const endISODate = normalizeToUtcISODate(new Date(holiday.endDate));

    for (
      let curDate = new Date(startISODate);
      curDate <= new Date(endISODate);
      curDate.setDate(curDate.getDate() + 1)
    ) {
      const curISODate = normalizeToUtcISODate(curDate);
      dayHolidaysMap.has(curISODate)
        ? dayHolidaysMap.set(
            curISODate,
            dayHolidaysMap.get(curISODate)!.concat(holiday)
          )
        : dayHolidaysMap.set(curISODate, [holiday]);
    }
  }

  return dayHolidaysMap;
};
