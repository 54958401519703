import { gql } from "@apollo/client";
import { BulkSelectBin_NodeFragment, Node } from "@generated/graphql";
import clsx from "clsx";
import { BulkSelectButton } from "./components/BulkSelectButton";

BulkSelectBin.fragments = {
  node: gql`
    fragment BulkSelectBin_Node on Node {
      id
      ...BulkSelectButton_Node
    }
    ${BulkSelectButton.fragments.node}
  `,
};

export enum ItemSelectionMode {
  Selected = "SELECTED",
  NotSelected = "NOT_SELECTED",
}

type Props = {
  items: BulkSelectBin_NodeFragment[];
  selectMode: ItemSelectionMode;
  onClick(itemId: Node["id"]): void;
  className?: string;
};

export function BulkSelectBin({
  items,
  selectMode,
  onClick,
  className = "h-36",
}: Props) {
  const isSelected = selectMode === ItemSelectionMode.Selected;

  return (
    <div
      className={clsx(
        "flex flex-row flex-wrap gap-2 justify-start content-start",
        "w-full p-2 overflow-y-auto",
        "border-solid border-2 rounded",
        isSelected ? "border-sky-500/60" : "border-slate-500/60",
        className
      )}
    >
      {items.map((item) => (
        <BulkSelectButton
          key={item.id}
          item={item}
          isSelected={isSelected}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
