import clsx from "clsx";
import { Icon } from "./Icon";
import { Tooltip } from "./Tooltip";

type Props = { className?: string; isAutomaticallyUpdated: boolean };

export const AttendanceRobot = ({
  className,
  isAutomaticallyUpdated,
}: Props) =>
  isAutomaticallyUpdated ? (
    <div className={clsx("flex items-center cursor-pointer", className)}>
      <Tooltip
        tooltipProps={{ place: "right" }}
        content="This attendance was automatically recorded by the system"
      >
        <Icon
          icon="robot"
          color="text-indigo-600"
          className="h-[22px] w-[22px]"
        />
      </Tooltip>
    </div>
  ) : null;
