import { useLayout } from "@contexts/LayoutProvider";
import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { useComponentsHeights } from "hooks/useComponentsHeights";
import { useEffect, useMemo, useState } from "react";
import { Icon, IconType } from "../Icon";
import { CardDetails } from "./components/CardDetails";
import { CardDetailsRow, CardSize, HeaderColors, HeightMode } from "./types";

const BORDERS = 4;
const TOGGLE_PX = 30;
const CARD_HEIGHT_INDEX = 0;
const BODY_HEIGHT_INDEX = 1;

type Props = {
  id?: string;
  size?: CardSize;
  icon?: IconType;
  className?: string;
  isCollapsed?: boolean;
  childUpdated?: boolean;
  bodyClassName?: string;
  header: React.ReactNode;
  rows?: CardDetailsRow[];
  isMinimizable?: boolean;
  heightMode?: HeightMode;
  headerClassName?: string;
  children?: React.ReactNode;
  minimizedCardHeight?: number;
  headerOverlayColor?: HeaderColors;
};

export const Card = ({
  id,
  rows,
  header,
  icon,
  children,
  className,
  size = "sm",
  isCollapsed,
  bodyClassName,
  headerClassName,
  minimizedCardHeight,
  heightMode = HeightMode.Fit,
  headerOverlayColor = HeaderColors.Blue,
}: Props) => {
  const style = getStyle(size);
  const isMinimizable = !!minimizedCardHeight;
  const minCardHeight = minimizedCardHeight || 0;

  const { setComponentDimension } = useLayout();
  const { setRef, heights } = useComponentsHeights();
  const [isMinimized, setIsMinimized] = useState(isMinimizable);

  // Calculations for Minimizable Cards
  const { showMinimizeToggle, miniMaxHeight } = useMemo(() => {
    const heightsSum = heights["content"] + heights["header"];
    const hasOverflow = heights["content"] > minCardHeight - heights["header"];
    const miniMaxHeight = heightsSum + (hasOverflow ? TOGGLE_PX : 0);
    const wouldOverflow = !isMinimized && miniMaxHeight > minCardHeight;
    return { miniMaxHeight, showMinimizeToggle: hasOverflow || wouldOverflow };
  }, [heights, isMinimized, minCardHeight]);

  useEffect(() => {
    const fullHeight = heights["header"] + heights["content"] + BORDERS;
    if (id) setComponentDimension(id, fullHeight);
  }, [heights, id, miniMaxHeight, isMinimizable, setComponentDimension]);

  const elementHeightStyles: string[] = useMemo(() => {
    const heightStyle = heightMode === HeightMode.Fit ? "fit" : "100%";
    if (minCardHeight === 0) return [heightStyle, heightStyle];
    return isMinimized
      ? [`${minCardHeight}px`, `${minCardHeight - heights["header"]}px`]
      : [`${miniMaxHeight}px`, `${miniMaxHeight - heights["header"]}px`];
  }, [heightMode, heights, miniMaxHeight, isMinimized, minCardHeight]);

  return (
    <div
      data-id="card"
      ref={setRef("card")}
      style={{ height: elementHeightStyles[CARD_HEIGHT_INDEX] }}
      className={clsx("flex flex-col w-full shadow", style.parent, className)}
    >
      {/* HEADER */}
      <div
        data-id="header"
        ref={setRef("header")}
        className={clsx(
          "relative flex items-center justify-start",
          "border border-gray-300 w-full overflow-hidden",
          "bg-gray-300 text-slate-800 leading-4 font-semibold",
          !isCollapsed && "rounded-b-none border-b-0",
          style.header,
          headerClassName
        )}
      >
        {headerOverlayColor && (
          <div
            className={clsx(
              `absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none`,
              headerOverlayColor
            )}
          />
        )}
        {icon && (
          <Icon icon={icon} size={style.iconSize} color="text-slate-700" />
        )}
        {header}
      </div>

      {/* BODY */}
      {!isCollapsed && (
        <div
          style={{ height: elementHeightStyles[BODY_HEIGHT_INDEX] }}
          className={clsx(
            "border w-full border-gray-300 bg-slate-50",
            "overflow-x-auto overflow-y-hidden",
            getScrollbarStyle("gray"),
            bodyClassName,
            style.body,
            isMinimizable && "relative overflow-hidden"
          )}
        >
          {/* BODY CONTENT */}
          <div
            data-id="content"
            ref={setRef("content")}
            className="flex w-full flex-col"
          >
            {rows && (
              <CardDetails heightMode={heightMode} rows={rows} size={size} />
            )}

            {children && children}
          </div>

          {/* Show More/Less Minimized Toggle Area */}
          {isMinimizable && showMinimizeToggle && (
            <div
              className={clsx(
                "absolute flex justify-center items-end h-[60px] w-full bottom-0 left-0 px-4",
                "bg-gradient-to-t from-slate-50 to-transparent hover:from-blue-100/60",
                "pb-[9px] font-semibold text-xs cursor-pointer hover:text-blue-900 text-slate-600"
              )}
              onClick={() => setIsMinimized(!isMinimized)}
            >
              {isMinimized ? "Show More" : "Show Less"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getStyle = (size: CardSize) => {
  switch (size) {
    case "sm":
      return {
        parent: "rounded-lg",
        header: "rounded-lg text-md !min-h-[40px] px-3 gap-x-2",
        iconSize: 5,
        headerHeight: 40,
        body: "rounded-b-lg",
      };
    default:
    case "md":
      return {
        parent: "rounded-lg",
        header: "rounded-lg text-lg !min-h-[44px] px-3 gap-x-2",
        iconSize: 5,
        headerHeight: 44,
        body: "rounded-b-lg",
      };
    case "lg":
      return {
        parent: "rounded-xl",
        header: "rounded-xl text-xl !min-h-[48px] px-4 gap-x-[10px]",
        iconSize: 6,
        headerHeight: 48,
        body: "rounded-b-xl",
      };
  }
};
