import {
  AttendanceScorecardStatus,
  CohortSessionStudentAttendanceStatus,
  CohortSessionTeacherAttendanceStatus,
  EngagementInstructionalSupportAttendanceStatus,
  MentorDashAttendanceStatus,
} from "@generated/graphql";
import { Route } from "@utils/routes/types";
import { IconType } from "components/shared/Icon";

export enum AdminMode {
  Dashboard = "Dashboard",
  Managing = "Managing",
  Staffing = "Staffing",
  ControlPanel = "Control Panel",
}

export type Breadcrumb = {
  name: string;
  route: Route;
  routeProps?: string[];
  current?: boolean;
  icon?: IconType;
};

export type VisitorDetails = {
  organizationName: string;
  engagementName: string;
  engagementStart: Date;
  engagementEnd: Date;
};

export type AttendanceStatus =
  | MentorDashAttendanceStatus
  | AttendanceScorecardStatus
  | CohortSessionStudentAttendanceStatus
  | CohortSessionTeacherAttendanceStatus
  | EngagementInstructionalSupportAttendanceStatus;
