import clsx from "clsx";
import { Icon } from "./Icon";
import { ThemeMode } from "./types";

type Props = {
  type: ThemeMode;
  border?: boolean;
  isToast?: boolean;
  className?: string;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  subtitle?: React.ReactNode;
  dismissToast?: () => void;
};

export const Banner = ({
  type,
  title,
  actions,
  subtitle,
  className,
  border = true,
  isToast = false,
  dismissToast,
}: Props) => {
  const { icon, tabStyles, bannerStyles, titleStyles, subtitleStyles } =
    getTypeStyles(type);

  return (
    <div
      className={clsx(
        "relative flex min-h-[60px] h-fit rounded-lg p-3 group",
        bannerStyles,
        !border && "!border-0",
        isToast && "!w-[550px] max-w-full opacity-90",
        className
      )}
    >
      {/* Colored left tab element */}
      <div
        className={clsx(
          "absolute top-[-1px] left-[-1px] w-[15px] h-[calc(100%+2px)] rounded-l-lg",
          tabStyles
        )}
      />
      {/* Icon */}
      <div className="flex flex-center w-[40px] md:w-[60px] md:pl-[5px]">
        {icon}
      </div>
      {/* Text */}
      <div className="flex flex-col justify-center items-start flex-1 gap-y-[1px] pr-4">
        {title && (
          <div
            className={clsx(
              "font-medium leading-snug flex-wrap w-full",
              titleStyles
            )}
          >
            {title}
          </div>
        )}
        {subtitle && (
          <div className={clsx("text-sm leading-snug w-full", subtitleStyles)}>
            {subtitle}
          </div>
        )}
      </div>
      {/* Actions */}
      {actions && (
        <div className="flex flex-center w-fit ml-3 pr-1 md:pr-0">
          {actions}
        </div>
      )}
      {isToast && dismissToast && (
        <div
          className={clsx(
            "absolute top-1 right-1 h-fit w-fit z-[1000] cursor-pointer",
            "text-gray-500/0 group-hover:text-gray-500"
          )}
          onClick={dismissToast}
        >
          <Icon
            icon="remove"
            size={7}
            color="text-inherit hover:text-rose-800"
          />
        </div>
      )}
    </div>
  );
};

type BannerTypeStyles = {
  tabStyles: string;
  bannerStyles: string;
  icon: React.ReactNode;
  titleStyles: string;
  subtitleStyles: string;
};

export const getTypeStyles = (
  type: "info" | "warning" | "error" | "success"
): BannerTypeStyles => {
  switch (type) {
    case "info":
      return {
        tabStyles: "bg-blue-500",
        titleStyles: "text-blue-800",
        subtitleStyles: "text-blue-700",
        bannerStyles: "bg-blue-50 border border-blue-400",
        icon: <Icon icon="info" size={6} color="text-blue-500" />,
      };
    case "warning":
      return {
        tabStyles: "bg-amber-500",
        titleStyles: "text-amber-800",
        subtitleStyles: "text-amber-700",
        bannerStyles: "bg-orange-50 border border-amber-400",
        icon: <Icon icon="warning" size={6} color="text-amber-500" />,
      };
    case "error":
      return {
        tabStyles: "bg-rose-500",
        titleStyles: "text-rose-800",
        subtitleStyles: "text-rose-700",
        bannerStyles: "bg-rose-50 border border-rose-400",
        icon: <Icon icon="error" size={6} color="text-rose-500" />,
      };
    case "success":
      return {
        tabStyles: "bg-emerald-500",
        titleStyles: "text-emerald-800",
        subtitleStyles: "text-emerald-700",
        bannerStyles: "bg-emerald-50 border border-emerald-400",
        icon: <Icon icon="check" size={6} color="text-emerald-500" />,
      };
  }
};
