import {
  CohortAssignmentSubject,
  TutoringPreferencesPosition,
  TutoringPreferencesTestPrepSubject,
} from "@generated/graphql";
import clsx from "clsx";

export const itemSectionHeaderStyle =
  "text-[18px] font-bold text-gray-700 leading-5";
export const itemHeaderStyle =
  "text-[16px] font-semibold text-gray-700 leading-5";
export const itemSubHeaderStyle =
  "text-[14px] font-semibold text-gray-700 leading-5";
export const itemContentStyle =
  "text-[14px] font-medium text-gray-700 leading-5";
export const itemSubContentStyle =
  "text-[13px] font-medium text-gray-600 leading-4";
export const listContentStyle =
  "text-[15px] font-medium text-gray-700 leading-5";

export const sectionWrapperStyle = "flex flex-col gap-y-2";
export const sectionBodyWrapperStyle =
  "flex items-center w-full h-fit p-3 border rounded-lg bg-slate-50/70";
export const sectionInputWrapperStyle =
  "flex flex-row gap-4 items-center justify-between w-full";
export const yesOrNoContainerStyle = "flex flex-row justify-between w-[22ch]";

export const languageItemStyle =
  "flex h-7 items-center text-[12px] leading-5 max-w-[250px] flex flex-wrap";

export const checklistLinkStyle = clsx(
  "flex items-center gap-x-[2px] underline-offset group ml-1",
  "text-blue-800/80 decoration-blue-800 cursor-pointer w-fit",
  "hover:decoration-blue-600 underline hover:text-blue-600"
);

export const cohortSubjectOptions: CohortAssignmentSubject[] = [
  CohortAssignmentSubject.Ela,
  CohortAssignmentSubject.SocialStudies,
  CohortAssignmentSubject.Math,
  CohortAssignmentSubject.Science,
];

type TeachingPositionMap = {
  [K in TutoringPreferencesPosition]: null;
};
const teachingPositionMap: TeachingPositionMap = {
  [TutoringPreferencesPosition.TeacherTutor]: null,
  [TutoringPreferencesPosition.InstructionalSupport]: null,
};
export const teachingPositionOptions = Object.keys(
  teachingPositionMap
) as TutoringPreferencesPosition[];

export const testPrepSubjectOptions: TutoringPreferencesTestPrepSubject[] = [
  TutoringPreferencesTestPrepSubject.English,
  TutoringPreferencesTestPrepSubject.Math,
  TutoringPreferencesTestPrepSubject.ReadingAndWriting,
  TutoringPreferencesTestPrepSubject.Science,
];

export const ellTooltipText =
  "Are you comfortable supporting English Language Learners? This may require you to add key strategies such as scaffolding, academic vocabulary, and a language objective to your ELA/Math etc. instruction.";
export const eldTooltipText =
  "Are you comfortable teaching systematic English Language Development (Speaking, Listening, Reading, Writing) to students who are newcomers to the US?";
export const flsTooltipText =
  "Are you comfortable teaching Foundational Literacy Skills?";
export const spedTooltipText =
  "Are you comfortable supporting special education students?";
export const testPrepTooltipText =
  "Are you comfortable teaching test prep courses? If so, which subjects?";
