import { TutoringPreferencesTestPrepSubject } from "@generated/graphql";
import { getTutoringPreferencesTestPrepSubjectText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  testPrepSubject: TutoringPreferencesTestPrepSubject;
  className?: string;
};

export const TutoringPreferencesTestPrepSubjectBadge = ({
  testPrepSubject,
  className,
}: Props) => (
  <Badge
    className={clsx(
      SubjectColors[testPrepSubject],
      "flex gap-x-0.5 text-center w-auto px-2 py-0.5 rounded-full whitespace-nowrap",
      className
    )}
  >
    {getTutoringPreferencesTestPrepSubjectText(testPrepSubject)}
  </Badge>
);

export const SubjectColors: {
  [key in TutoringPreferencesTestPrepSubject]: string;
} = {
  [TutoringPreferencesTestPrepSubject.English]:
    "text-emerald-900 bg-emerald-200",
  [TutoringPreferencesTestPrepSubject.Math]: "text-purple-900 bg-purple-200",
  [TutoringPreferencesTestPrepSubject.ReadingAndWriting]:
    "text-green-900 bg-green-200",
  [TutoringPreferencesTestPrepSubject.Science]: "text-teal-900 bg-teal-200",
};
