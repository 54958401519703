export type DraftTutoringPreferencesLanguage = {
  code: string | null;
  fluent: boolean;
  comfortSupport: boolean;
  comfortFull: boolean;
};

type NonNullLanguage = Omit<DraftTutoringPreferencesLanguage, "code"> & {
  code: string;
};

export function hasNonNullCode(
  lang: DraftTutoringPreferencesLanguage
): lang is NonNullLanguage {
  return lang.code !== null;
}
