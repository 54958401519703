import clsx from "clsx";
import { Badge, Tooltip } from "components/shared";

type Props = {
  className?: string;
  isPaidExternally: boolean;
};

export const PaymentExternalityBadge = ({
  className,
  isPaidExternally,
}: Props) => (
  <Tooltip
    content={
      isPaidExternally
        ? "This Teacher-Tutor's payments are handled externally—not by TbT."
        : "This Teacher-Tutor's payments are handled by TbT."
    }
  >
    <Badge
      className={clsx(
        paymentExternalityColours(isPaidExternally),
        "mr-5",
        className
      )}
    >
      {isPaidExternally ? "External Pay" : "Auto Pay"}
    </Badge>
  </Tooltip>
);

const paymentExternalityColours = (isPaidExternally: boolean) =>
  isPaidExternally
    ? "text-orange-900 bg-orange-200"
    : "text-sky-900 bg-sky-200";
