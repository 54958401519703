import { Icon } from "./Icon";

type Props = {
  debouncePending: boolean;
  mutationLoading: boolean;
};

export const NoteStatusIndicator = ({
  debouncePending,
  mutationLoading,
}: Props) => (
  <div className="h-fit w-fit">
    {debouncePending ? (
      <div className="relative">
        <Icon icon="keyboard" size={6} className="absolute animate-ping" />
        <Icon icon="keyboard" size={6} />
      </div>
    ) : mutationLoading ? (
      <Icon icon="doubleSpinner" size={6} className="animate-spin" />
    ) : (
      <Icon icon="checkmark" size={6} />
    )}
  </div>
);
