import { QRCodeErrorCorrectionLevel } from "qrcode";
import { Pixel } from "./numbers";

export function getQrCodeImageUrl(
  text: string,
  sizePx: Pixel,
  margin?: number,
  ecLevel?: QRCodeErrorCorrectionLevel
): string {
  return `/api/qr?text=${encodeURIComponent(text)}&size=${sizePx}${
    margin !== undefined ? `&margin=${margin}` : ""
  }${ecLevel ? `&ecLevel=${ecLevel}` : ""}`;
}
