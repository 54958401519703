import { gql } from "@apollo/client";
import { SignAgreementsChecklistItem_CompletedAgreementFragment } from "@generated/graphql";
import { ChecklistItem } from "components/shared";
import { SignAgreementsContent } from "./components/SignAgreementsContent";

SignAgreementsChecklistItem.fragments = {
  completedAgreement: gql`
    fragment SignAgreementsChecklistItem_CompletedAgreement on CompletedAgreement {
      ...SignAgreementsContent_CompletedAgreement
    }
    ${SignAgreementsContent.fragments.completedAgreement}
  `,
};

type Props = {
  hasPendingAgreements: boolean | null | undefined;
  completedAgreements?:
    | SignAgreementsChecklistItem_CompletedAgreementFragment[]
    | null;
};

export function SignAgreementsChecklistItem({
  hasPendingAgreements,
  completedAgreements,
}: Props) {
  const isComplete = !hasPendingAgreements;

  return (
    <ChecklistItem
      trackedForCompletion
      complete={isComplete}
      title="Sign all agreements"
      loading={!completedAgreements}
      collapsibleContent={
        completedAgreements != null && (
          <SignAgreementsContent
            hasPendingAgreements={!isComplete}
            completedAgreements={completedAgreements}
          />
        )
      }
    />
  );
}
