import { env } from "next-runtime-env";
export enum Env {
  DEV = "development",
  TEST = "test",
}

const cookieDomain =
  getEnv() === "development"
    ? "localhost"
    : env("NEXT_PUBLIC_WEB_COOKIE_DOMAIN");

const cookieSecure = getEnv() === "development" ? false : true;

export const config = {
  env: getEnv(),

  cookieDomain,
  cookieSecure,

  aiAssistantAuth: {
    password: env("NEXT_PUBLIC_QA_APP_PASSWORD"),
  },

  s3: {
    bucket: env("NEXT_PUBLIC_RECORDINGS_BUCKET_NAME"),
    region: env("NEXT_PUBLIC_REGION"),
  },

  cognitoAuth: {
    mandatorySignIn: true,
    region: env("NEXT_PUBLIC_REGION"),
    userPoolId: env("NEXT_PUBLIC_USER_POOL_ID"),
    userPoolWebClientId: env("NEXT_PUBLIC_USER_POOL_CLIENT_ID"),
    cookieStorage: {
      domain: cookieDomain,
      secure: cookieSecure,
      path: "/",
      expires: 365,
    },
  },

  cognitoVisitorAuth: {
    mandatorySignIn: true,
    userPoolId: env("NEXT_PUBLIC_USER_VISITOR_POOL_ID"),
    userPoolWebClientId: env("NEXT_PUBLIC_USER_VISITOR_POOL_CLIENT_ID"),
    cookieStorage: {
      domain: cookieDomain,
      secure: cookieSecure,
      path: "/",
      expires: 365,
    },
  },

  graphql: {
    httpUrl: env("NEXT_PUBLIC_API_URL"),
    webSocketUrl: env("NEXT_PUBLIC_WS_API_URL"),
  },
};

function getEnv() {
  if (process.env.NODE_ENV === "test") return Env.TEST;

  return process.env.NODE_ENV !== null && process.env.NODE_ENV !== undefined
    ? process.env.NODE_ENV
    : Env.DEV;
}
