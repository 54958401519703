import { gql, useMutation } from "@apollo/client";
import {
  AddInfractionModalMutation,
  AddInfractionModalMutationVariables,
  Cohort,
  Engagement,
  InfractionType,
  RosterRole,
  User,
} from "@generated/graphql";
import { infractionTypeOptions } from "@utils/selectMenuOptions";
import clsx from "clsx";
import { findIndex } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ToggleButton } from "../Buttons";
import { ErrorBox } from "../Error";
import { SelectMenu, TextArea } from "../Inputs";
import { Modal } from "../Modal";
import { triggerSuccessToast } from "../Toast";

const ADD_INFRACTION_MUTATION = gql`
  mutation AddInfractionModal($input: AddInfractionInput!) {
    addInfraction(input: $input)
  }
`;

type Props = {
  show: boolean;
  role: RosterRole;
  teacherName: string;
  teacherId: User["id"];
  cohortId?: Cohort["id"];
  engagementId: Engagement["id"];
  closeModal: () => void;
};

type InfractionInputs = {
  type: InfractionType;
  details: string;
};

export const AddInfractionModal = ({
  role,
  show,
  cohortId,
  teacherId,
  teacherName,
  engagementId,
  closeModal,
}: Props) => {
  const [infractionType, setInfractionType] = useState<InfractionType | null>(
    null
  );
  const [details, setDetails] = useState<string | null>(null);
  const [reviewForDestaff, setReviewForDestaff] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [addInfraction, { loading }] = useMutation<
    AddInfractionModalMutation,
    AddInfractionModalMutationVariables
  >(ADD_INFRACTION_MUTATION, {
    onCompleted: ({ addInfraction }) => {
      triggerSuccessToast({
        message: `Infraction successfully added for ${teacherName}.`,
        sub: new Date(addInfraction).toLocaleString(),
      });
      closeModal();
    },
    onError: (error) => {
      setErrorMsg(
        "There was an error adding the infraction. Please reach out in #talk-tech."
      );
      console.error(error);
    },
  });

  const onSubmit = (data: InfractionInputs) => {
    clearErrors();
    addInfraction({
      variables: {
        input: {
          teacherId,
          type: data.type,
          role,
          details: data.details,
          reviewForDestaff,
          engagementId,
          cohortId,
        },
      },
    });
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<InfractionInputs>({ mode: "onSubmit" });

  return (
    <Modal
      show={show}
      onClose={closeModal}
      icon={<Modal.Icon icon="infraction" />}
      title={"Teacher Infraction"}
      width="medium"
    >
      <div className="flex flex-col gap-4 p-4">
        <div className="text-lg font-bold">
          Name: <span className="text-gray-400 px-2">{teacherName}</span>
        </div>

        <SelectMenu
          labelText="Type"
          required
          options={infractionTypeOptions}
          {...register("type", {
            validate: (value) => {
              if (!value) return "Please select a type.";
              return true;
            },
          })}
          onSelect={({ infractionType }) => {
            if (infractionType === null) {
              setInfractionType(null);
              return;
            }

            clearErrors("type");
            setInfractionType(infractionType);
            setValue("type", infractionType);
          }}
          initialIndex={
            infractionType
              ? findIndex(infractionTypeOptions, { infractionType })
              : 0
          }
          listAlignment="left"
          className="col-span-8 sm:col-span-3"
          error={errors.type?.message}
        />

        <TextArea
          id={"details"}
          rows={4}
          value={details || ""}
          required
          placeholder="Details"
          {...register("details", {
            required: {
              value: true,
              message: "Details are required.",
            },
          })}
          onChange={(e) => {
            clearErrors("details");
            setDetails(e.target.value);
          }}
          error={errors.details?.message}
        />

        <div className="py-2">
          <div>Review for potential destaff?</div>
          <div className="flex flow-row gap-4 ">
            <div
              className={clsx(
                "font-bold",
                !reviewForDestaff && "text-gray-400"
              )}
            >
              No
            </div>

            <ToggleButton
              toggleState={reviewForDestaff}
              handleToggle={(toggleState: boolean) => {
                setReviewForDestaff(toggleState);
              }}
            />
            <div
              className={clsx("font-bold", reviewForDestaff && "text-gray-400")}
            >
              Yes
            </div>
          </div>
          {reviewForDestaff && (
            <div className="text-red-500">
              {
                "The staffing team will be notified to review this teacher's performance"
              }
            </div>
          )}
        </div>
      </div>

      <ErrorBox msg={errorMsg} />

      <Modal.Buttons>
        <Modal.Button
          type="cancel"
          disabled={loading}
          onClick={() => {
            setInfractionType(null);
            setDetails(null);
            clearErrors();
            closeModal();
          }}
        >
          Cancel
        </Modal.Button>
        <Modal.Button
          type="confirm"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        >
          Submit
        </Modal.Button>
      </Modal.Buttons>
    </Modal>
  );
};
