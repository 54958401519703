import clsx from "clsx";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

type Props = {
  disabled?: boolean;
  className?: string;
  toggleState: boolean;
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  handleToggle: (toggleState: boolean) => void;
};

export const ToggleButton = ({
  label,
  tooltip,
  disabled,
  toggleState,
  className = "",
  handleToggle,
}: Props) => (
  <div className={clsx("inline-flex items-center mr-[3px]", className)}>
    {label && <span className="mr-[8px]">{label}</span>}
    {tooltip && (
      <Tooltip
        className={clsx(
          disabled ? "text-red-500" : " text-blue-600",
          "w-5 h-5 text-blue-600 mr-1 cursor-help"
        )}
        content={
          <div className="block w-48 text-sm text-center">{tooltip}</div>
        }
        tooltipProps={{ place: "bottom" }}
      />
    )}
    <div
      className={clsx(
        "relative w-[42px] h-[22px] rounded-full",
        "transition duration-300 ease-in-out border border-slate-400/80",
        toggleState ? "bg-blue-500" : "bg-slate-200",
        disabled
          ? toggleState
            ? "!bg-blue-900/50"
            : "!bg-slate-300/80"
          : "cursor-pointer"
      )}
      onClick={() => !disabled && handleToggle(!toggleState)}
    >
      {/* Circle Indicator */}
      <div
        className={clsx(
          "flex flex-center bg-white",
          "relative w-[18px] h-[18px] rounded-full top-[1px]",
          "transition-all duration-300 ease-in-out",
          toggleState ? "bg-blue-500 left-[21px]" : "left-[1px]"
        )}
      >
        {disabled && (
          <Icon
            icon="disabled"
            size={4}
            color="text-slate-300"
            className="pr-[0.5px]"
          />
        )}
      </div>
    </div>
  </div>
);
