import { useEffect, useRef, useState } from "react";

export const useComponentsHeights = () => {
  const refs = useRef(new Map<string, HTMLDivElement>()); // Store refs dynamically
  const [heights, setHeights] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setHeights((prev) => {
        const updatedHeights = { ...prev };
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement; // Cast target to HTMLElement
          const id = target.dataset.id; // Get data-id attribute
          if (id) updatedHeights[id] = entry.contentRect.height;
        });
        return updatedHeights;
      });
    });

    refs.current.forEach((el) => el && observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const setRef = (id: string) => (el: HTMLDivElement | null) => {
    if (el) refs.current.set(id, el);
  };

  return { setRef, heights };
};
