import clsx from "clsx";
import { Button, Icon, Tooltip } from "components/shared";
import {
  eldTooltipText,
  ellTooltipText,
  flsTooltipText,
  itemSectionHeaderStyle,
  itemSubHeaderStyle,
  spedTooltipText,
  testPrepTooltipText,
} from "./constants";

export const renderContent = (text: string) => (
  <div className="text-sm text-center font-normal max-w-[32ch]">{text}</div>
);

const tooltip = (label: string, content: string, inCard = false) => (
  <Tooltip
    className={clsx(
      !inCard && itemSectionHeaderStyle,
      "flex items-center gap-x-1"
    )}
    content={renderContent(content)}
  >
    {label}
    <Icon icon="infoCircle" size={3} color="text-blue-500" className="mb-2" />
  </Tooltip>
);

export const EllTooltip = ({ inCard = false }: { inCard?: boolean }) =>
  tooltip("ELL", ellTooltipText, inCard);

export const EldTooltip = ({ inCard = false }: { inCard?: boolean }) =>
  tooltip("ELD", eldTooltipText, inCard);

export const FlsTooltip = ({ inCard = false }: { inCard?: boolean }) =>
  tooltip("FLS", flsTooltipText, inCard);

export const SpedTooltip = ({ inCard = false }: { inCard?: boolean }) =>
  tooltip("SPED", spedTooltipText, inCard);

export const TestPrepTooltip = ({ inCard = false }: { inCard?: boolean }) =>
  tooltip("PSAT/SAT/ACT", testPrepTooltipText, inCard);

export const getNoDataCallToAction = (openModal: () => void, text: string) => (
  <div className="flex w-full items-center justify-between gap-x-3 p-3">
    <div className={clsx(itemSubHeaderStyle, "flex flex-wrap")}>{text}</div>

    <Button
      height="sm"
      theme="slate"
      className="min-w-[112px]"
      onClick={openModal}
    >
      Let us know!
    </Button>
  </div>
);
