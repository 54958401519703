import { RecordingMetricResultReviewStatus } from "@generated/graphql";
import { getRecordingMetricResultReviewStatusText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  reviewStatus: RecordingMetricResultReviewStatus | null | undefined;
};

export function RecordingMetricResultReviewStatusBadge({
  reviewStatus,
}: Props) {
  return (
    <Badge className={clsx(reviewStatusClassMap[reviewStatus ?? "NONE"])}>
      {reviewStatus
        ? getRecordingMetricResultReviewStatusText(reviewStatus)
        : "Unverified"}
    </Badge>
  );
}

const reviewStatusClassMap: Record<
  RecordingMetricResultReviewStatus | "NONE",
  string
> = {
  NONE: "text-gray-800 bg-gray-200",
  [RecordingMetricResultReviewStatus.Inaccurate]: "text-green-800 bg-green-200",
  [RecordingMetricResultReviewStatus.VerifiedMinor]:
    "text-orange-800 bg-orange-200",
  [RecordingMetricResultReviewStatus.VerifiedSevere]:
    "text-rose-800 bg-rose-200",
};
