import { DestaffReason } from "@generated/graphql";
import { Badge } from "./Badge";

const reasonBadgeMap: {
  [key in DestaffReason]: { title: string; style: string };
} = {
  [DestaffReason.DroppedFt]: {
    title: "Dropped - FT Job",
    style: "text-purple-900 bg-purple-200",
  },
  [DestaffReason.DroppedSchedule]: {
    title: "Dropped - Schedule Change",
    style: "text-sunset-900 bg-sunset-200",
  },
  [DestaffReason.DidNotConfirm]: {
    title: "Did Not Confirm",
    style: "text-rose-900 bg-rose-200",
  },
  [DestaffReason.ProgramChange]: {
    title: "Program Change",
    style: "text-orange-900 bg-orange-200",
  },
  [DestaffReason.CohortCancelled]: {
    title: "Cohort Cancelled",
    style: "text-gray-900 bg-gray-200",
  },
};

type Props = { reason?: DestaffReason | null };

export const DestaffReasonBadge = ({ reason }: Props) => {
  const rsn = reason ?? DestaffReason.DidNotConfirm;
  return (
    <Badge className={reasonBadgeMap[rsn].style}>
      {reasonBadgeMap[rsn].title}
    </Badge>
  );
};
