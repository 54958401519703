import { JobPostingType } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  type: JobPostingType;
  className?: string;
};

export const JobPostingTypeBadge = ({ type, className }: Props) => (
  <Badge className={clsx(jobPostingTypeMap[type], className)}>
    {getJobPostingTypeText(type)}
  </Badge>
);

export const getJobPostingTypeText = (type: JobPostingType): string => {
  switch (type) {
    case JobPostingType.Launch:
      return "Launch";
    case JobPostingType.Restaff:
      return "Restaff";
    default:
      return "Unknown Status";
  }
};

const jobPostingTypeMap: {
  [key in JobPostingType]: string;
} = {
  [JobPostingType.Launch]: "text-yellow-900 bg-yellow-200",
  [JobPostingType.Restaff]: "text-orange-900 bg-orange-200",
};
