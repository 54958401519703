import { Icon, Tooltip } from "@shared";
import clsx from "clsx";

type Props = {
  completed?: boolean;
};
export const FingerprintingBadge = ({ completed }: Props) => (
  <Tooltip content={`Fingerprinting ${completed ? "C" : "Inc"}omplete`}>
    <div
      className={clsx(
        "flex flex-center rounded-full w-5 h-5 cursor-pointer",
        completed ? "bg-emerald-500" : "bg-red-500"
      )}
    >
      <Icon icon="fingerprint" color="text-white" size={4} />
    </div>
  </Tooltip>
);
