import { gql } from "@apollo/client";
import { BulkSelect_NodeFragment, Node } from "@generated/graphql";
import { Icon, Tooltip } from "components/shared";
import { BulkSelectBin, ItemSelectionMode } from "./components/BulkSelectBin";

BulkSelect.fragments = {
  node: gql`
    fragment BulkSelect_Node on Node {
      id
      ...BulkSelectBin_Node
    }
    ${BulkSelectBin.fragments.node}
  `,
};

type Props = {
  itemName: string;
  selectedItems: BulkSelect_NodeFragment[];
  deselectedItems: BulkSelect_NodeFragment[];
  setSelectedItemIds: React.Dispatch<React.SetStateAction<Node["id"][]>>;
  selectedTooltipContent?: React.ReactNode;
  binClassName?: string;
};

export function BulkSelect({
  itemName,
  selectedItems,
  deselectedItems,
  setSelectedItemIds,
  selectedTooltipContent,
  binClassName,
}: Props) {
  const selectItem = (itemId: Node["id"]) => {
    setSelectedItemIds((prev) => [...prev, itemId]);
  };
  const deselectItem = (itemId: Node["id"]) => {
    setSelectedItemIds((prev) => prev.filter((id) => id !== itemId));
  };

  return (
    <div className="grid grid-cols-9 gap-y-1">
      <div className="col-span-4 text-center">
        <h2 className="font-medium text-gray-600">Not Selected</h2>
      </div>
      <div className="col-span-1" />
      <div className="col-span-4 flex flex-row justify-center gap-x-1 text-center">
        <h2 className="font-medium text-gray-600">Selected</h2>
        {selectedTooltipContent && (
          <Tooltip
            className="cursor-help"
            tooltipProps={{ place: "top", delayShow: 250 }}
            content={selectedTooltipContent}
          />
        )}
      </div>
      <div className="col-span-4">
        <BulkSelectBin
          className={binClassName}
          items={deselectedItems}
          selectMode={ItemSelectionMode.NotSelected}
          onClick={selectItem}
        />
      </div>
      <div className="flex flex-col flex-center col-span-1 gap-y-2">
        <Tooltip
          content={`Select all ${itemName}s`}
          tooltipProps={{ place: "top", delayShow: 250 }}
          className="bg-sky-500 rounded-full p-1.5"
        >
          <Icon
            icon="anglesRight"
            size={7}
            color="text-white"
            onClick={() =>
              setSelectedItemIds((prev) =>
                prev.concat(deselectedItems.map(({ id }) => id))
              )
            }
          />
        </Tooltip>
        <Tooltip
          content={`De-select all ${itemName}s`}
          tooltipProps={{ place: "bottom", delayShow: 250 }}
          className="bg-rose-500 rounded-full p-1.5"
        >
          <Icon
            icon="anglesLeft"
            size={7}
            color="text-white"
            onClick={() => setSelectedItemIds([])}
          />
        </Tooltip>
      </div>
      <div className="col-span-4">
        <BulkSelectBin
          className={binClassName}
          items={selectedItems}
          selectMode={ItemSelectionMode.Selected}
          onClick={deselectItem}
        />
      </div>
    </div>
  );
}
