import { AvailabilityStatus } from "@generated/graphql";
import { Badge } from "@shared";
import clsx from "clsx";

type Props = {
  availabilityStatus: AvailabilityStatus;
  className?: string;
};

export const AvailabilityBadge = ({ availabilityStatus, className }: Props) => (
  <Badge className={clsx(availabilityStatusMap[availabilityStatus], className)}>
    {getAvailabilityStatusText(availabilityStatus)}
  </Badge>
);

export const getAvailabilityStatusText = (
  availabilityStatus: AvailabilityStatus
): string => {
  switch (availabilityStatus) {
    case AvailabilityStatus.Available:
      return "Available";
    case AvailabilityStatus.Busy:
      return "Busy";
    default:
      return "Unknown Status";
  }
};

const availabilityStatusMap: {
  [key in AvailabilityStatus]: string;
} = {
  [AvailabilityStatus.Available]: "text-blue-900 bg-blue-200",
  [AvailabilityStatus.Busy]: "text-rose-900 bg-rose-200",
};
